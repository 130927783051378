<template>
  <v-card class="card card-test">
    <div class="card__body">
      <div class="card__main pb-2">
        <div class="px-3 py-2">
          <h5 class="card__title">{{ title }}</h5>
          <h6 class="card__sub-title" v-if="action">{{ sub_title }}</h6>
          <p class="card__desc">
            {{ desc }}
          </p>
          <p class="card__desc">
            {{ $t("validity") }} {{ data.valid_for_days }} {{ $t("days") }}
          </p>
        </div>
        <div
          class="card__action card__action--remove pa-3"
          v-if="checkItem(data)"
          @click="removeCart(data, null)"
        >
          <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
        </div>
        <div
          class="card__action card__action--add pa-3"
          v-else
          @click="addCart(data, null)"
        >
          <v-icon color="#e1e8f5"> mdi-cart-plus </v-icon>
        </div>
        <p class="float-border">{{ $t("or_use_deal") }}</p>
      </div>
      <template v-if="listDeal != null">
        <div v-for="(deal, index) of listDeal" :key="index">
          <div class="card__deal mt-2">
            <div class="d-flex">
              <svg
                width="84"
                height="72"
                viewBox="0 0 84 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="pl-3 py-2"
              >
                <path
                  d="M3.42037 3.37598V68.611H43.3545C67.4704 68.611 80.5954 57.4724 80.5954 35.914C80.5954 13.7722 66.3652 3.37598 43.3545 3.37598H3.42037ZM43.3545 0C78.7211 0 84 22.4935 84 35.914C84 59.1812 69.5625 72 43.3545 72H0V0H43.3545ZM8.778 40.6993H21.5591V63.2891H8.778V40.6993ZM23.1079 29.3314C23.1079 30.3669 22.9023 31.3922 22.503 32.3489C22.1037 33.3056 21.5184 34.1748 20.7806 34.907C20.0427 35.6392 19.1668 36.22 18.2028 36.6163C17.2387 37.0125 16.2055 37.2165 15.162 37.2165C14.1185 37.2165 13.0853 37.0125 12.1212 36.6163C11.1572 36.22 10.2813 35.6392 9.54342 34.907C8.80558 34.1748 8.22029 33.3056 7.82097 32.3489C7.42165 31.3922 7.21613 30.3669 7.21613 29.3314C7.21613 18.8179 23.1052 18.8179 23.1052 29.3314H23.1079ZM27.9851 25.6533V33.0226H30.2662C31.8412 33.0226 33.3034 32.5745 33.3034 29.2741C33.3034 26.0753 31.6759 25.6507 30.2662 25.6507L27.9851 25.6533ZM65.9689 22.0064H69.657V33.0226H75.1144C73.6103 13.0246 57.7658 8.71085 43.3545 8.71085H28.0009V22.0064H30.2689C34.4137 22.0064 36.9967 24.7988 36.9967 29.2767C36.9967 33.9161 34.4846 36.6721 30.2689 36.6721H28.0009V63.3048H43.3545C66.7721 63.3048 74.9201 52.5074 75.2089 36.6721H65.982V21.9933L65.9689 22.0064ZM55.713 30.1858H58.38L57.1489 26.0362H56.931L55.713 30.1858ZM49.9564 36.6825L54.4294 22.0038H59.6662L64.1209 36.6825H60.2962L59.4458 33.8353H54.6341L53.802 36.6825H49.9564ZM48.195 33.0226V36.6695H39.0889V22.0064H47.9089V25.6533H42.7665V27.3647H47.6175V31.0116H42.7665V33.0226H48.195Z"
                  fill="#213B8F"
                />
              </svg>
              <div class="px-3 py-2">
                <h5 class="card__deal__title">{{ deal.name }}</h5>
                <p class="card__deal__disc" v-if="action">
                  IDR {{ formatPrice(deal.amount_before_deal) }}
                </p>
                <h6 class="card__deal__sub-title" v-if="action">
                  IDR {{ formatPrice(deal.amount_after_deal) }}
                </h6>
                <p class="card__deal__desc">
                  {{ `${$t("validity")} ${deal.expired_days} ${$t("days")}` }}
                </p>
              </div>
            </div>
            <div
              class="card__action card__action--remove pa-3"
              v-if="checkItemDeal(data, deal.id || null)"
              @click="removeCartDeal(deal, data.id || null)"
            >
              <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
            </div>
            <div
              class="card__action card__action--add pa-3"
              v-else
              @click="addCartDeal(deal, data.id)"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 30 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.91089 25.2475C10.5446 25.2475 11.8812 26.5842 11.8812 28.2178C11.8812 29.8515 10.5446 31.1881 8.91089 31.1881C7.27723 31.1881 5.94059 29.8515 5.94059 28.2178C5.94059 26.5842 7.27723 25.2475 8.91089 25.2475ZM23.7624 25.2475C25.396 25.2475 26.7327 26.5842 26.7327 28.2178C26.7327 29.8515 25.396 31.1881 23.7624 31.1881C22.1287 31.1881 20.7921 29.8515 20.7921 28.2178C20.7921 26.5842 22.1287 25.2475 23.7624 25.2475ZM9.20792 20.495C9.20792 20.6436 9.35643 20.7921 9.50495 20.7921H26.7327V23.7624H8.91089C7.27723 23.7624 5.94059 22.4257 5.94059 20.7921C5.94059 20.198 6.08911 19.7525 6.23762 19.3069L8.16832 15.7426L2.9703 4.45545H0V1.48515H4.90099L11.2871 14.8515H21.6832L27.4752 4.45545L30 5.94059L24.2079 16.3366C23.7624 17.2277 22.7228 17.8218 21.6832 17.8218H10.5446L9.20792 20.198V20.495ZM12.4752 0C13.6634 0 14.5545 0.891089 14.5545 2.07921C14.5545 3.26733 13.6634 4.15842 12.4752 4.15842C11.2871 4.15842 10.396 3.26733 10.396 2.07921C10.396 0.891089 11.4356 0 12.4752 0ZM20.198 11.8812C19.0099 11.8812 18.1188 10.9901 18.1188 9.80198C18.1188 8.61386 19.0099 7.72277 20.198 7.72277C21.3861 7.72277 22.2772 8.61386 22.2772 9.80198C22.2772 10.9901 21.2376 11.8812 20.198 11.8812ZM12.1782 11.8812L10.396 10.099L20.495 0L22.2772 1.78218L12.1782 11.8812Z"
                  fill="rgb(225, 232, 245)"
                />
              </svg>
            </div>
          </div>
          <p class="card__deal__footer">
            {{ $t("deal_available_until") }} {{ formatDate(deal.end_date) }}
          </p>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    listDeal: {
      type: Array,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "-",
    },
    sub_title: {
      type: String,
      default: "-",
    },
    desc: {
      type: String,
      default: "-",
    },
    plant: {
      type: [String, Number],
      default: "",
    },
    subscribe: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkItem(item) {
      return this.$store.state.cart.findIndex(
        res =>
          res.package != null && res.package.id == item.id && res.deal == null
      ) > -1
        ? true
        : false;
    },
    checkItemDeal(item, deal) {
      const indexpa = this.$store.state.cart.findIndex(
        res =>
          res.package != null &&
          res.package.id == item.id &&
          res.deal != null &&
          deal == res.deal.id
      );
      return indexpa > -1;
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addCart(param, param2) {
      this.$emit("addCart", param, param2);
    },
    removeCart(param, param2) {
      this.$emit("removeCart", param, param2);
    },
    addCartDeal(param, param2) {
      let data = param;
      data.from_deal = true;
      this.$emit("addCart", data, param2);
    },
    removeCartDeal(param, param2) {
      this.$emit("removeCart", param, param2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.card {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  &__body {
    width: 100%;
  }
  &__main {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px dashed rgba(102, 102, 102, 0.2);
  }
  &__deal {
    display: flex;
    justify-content: space-between;
    &__footer {
      margin: 0;
      font-size: 12px;
      color: red;
      padding: 6px 12px;
      background: #eeeeee;
    }
  }
  &__action {
    border-radius: 0 !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.9;
    }
  }
  &__action--add {
    display: flex;
    background: $main_1;
  }
  &__action--remove {
    display: flex;
    background: $red;
  }
  &__title {
    cursor: pointer;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $dark_blue_3;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $main_1;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__desc {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $gray_4;
  }
  &__deal {
    color: #213b8f;
    &__disc {
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: $gray_3;
      text-decoration: line-through;
    }
    &__title {
      cursor: pointer;
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: $dark_blue_3;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    &__sub-title {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: $main_1;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    &__desc {
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: $gray_4;
    }
  }
  a {
    text-decoration: none;
  }
  .float-border {
    margin: 0;
    font-size: 10px;
    position: absolute;
    background: white;
    padding: 0 8px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    bottom: -8px;
    color: #666666;
  }
}
</style>
