<template>
  <v-card class="card card-test">
    <div class="card__body px-3 pa-2">
      <localized-link
        :to="`/patient/buy-package/${data.id}?plant=${plant}`"
        v-if="plant != null && plant != ''"
      >
        <h5 class="card__title">{{ title }}</h5>
      </localized-link>
      <h5 class="card__title" v-else @click="$emit('detail')">{{ title }}</h5>
      <h6 class="card__sub-title" v-if="action">{{ sub_title }}</h6>
      <p class="card__desc">
        {{ desc }}
      </p>
      <p v-if="data.has_deal" class="mb-0 card__desc card__deal">
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.488625 0.468886V9.5293H6.1935C9.63863 9.5293 11.5136 7.98227 11.5136 4.98806C11.5136 1.91281 9.48075 0.468886 6.1935 0.468886H0.488625ZM6.1935 0C11.2459 0 12 3.1241 12 4.98806C12 8.21961 9.9375 10 6.1935 10H0V0H6.1935ZM1.254 5.65268H3.07987V8.79016H1.254V5.65268ZM3.30113 4.07381C3.30113 4.21762 3.27176 4.36003 3.21472 4.4929C3.15767 4.62577 3.07406 4.7465 2.96865 4.8482C2.86325 4.94989 2.73811 5.03056 2.60039 5.08559C2.46267 5.14063 2.31507 5.16896 2.166 5.16896C2.01693 5.16896 1.86933 5.14063 1.73161 5.08559C1.59389 5.03056 1.46875 4.94989 1.36335 4.8482C1.25794 4.7465 1.17433 4.62577 1.11728 4.4929C1.06024 4.36003 1.03087 4.21762 1.03087 4.07381C1.03087 2.6136 3.30075 2.6136 3.30075 4.07381H3.30113ZM3.99788 3.56295V4.58647H4.32375C4.54875 4.58647 4.75763 4.52424 4.75763 4.06585C4.75763 3.62156 4.52513 3.56259 4.32375 3.56259L3.99788 3.56295ZM9.42413 3.05644H9.951V4.58647H10.7306C10.5157 1.80897 8.25225 1.20984 6.1935 1.20984H4.00012V3.05644H4.32413C4.91625 3.05644 5.28525 3.44428 5.28525 4.06621C5.28525 4.71056 4.92637 5.09334 4.32413 5.09334H4.00012V8.79233H6.1935C9.53888 8.79233 10.7029 7.29269 10.7441 5.09334H9.426V3.05463L9.42413 3.05644ZM7.959 4.19247H8.34L8.16413 3.61614H8.133L7.959 4.19247ZM7.13663 5.09479L7.77563 3.05608H8.52375L9.16012 5.09479H8.61375L8.49225 4.69935H7.80487L7.686 5.09479H7.13663ZM6.885 4.58647V5.09298H5.58413V3.05644H6.84412V3.56295H6.1095V3.80065H6.8025V4.30716H6.1095V4.58647H6.885Z"
            fill="#213B8F"
          />
        </svg>
        {{ $t("deal_available") }}
      </p>
    </div>
    <template v-if="action && checkAvailableGender">
      <template v-if="subscribe">
        <div
          class="card__action card__action--remove pa-3"
          v-if="active"
          @click="removeCart(data, data.id)"
        >
          <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
        </div>
        <div
          class="card__action card__action--add pa-3"
          v-else
          @click="addCart(data, data.id)"
        >
          <v-icon color="#e1e8f5"> mdi-cart-plus </v-icon>
        </div>
      </template>
      <template v-else>
        <div
          class="card__action card__action--remove pa-3"
          v-if="active"
          @click="removeCartPackage(data)"
        >
          <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
        </div>
        <div
          class="card__action card__action--add pa-3"
          v-else
          @click="addCartPackage(data)"
        >
          <!-- subs false -->
          <v-icon color="#e1e8f5"> mdi-cart-plus </v-icon>
        </div>
      </template>
      <!--       
      <localized-link
        class="d-flex"
        :to="`/patient/order-subscription/${data.id}?plant=${plant}`"
        v-if="subscribe"
      >
        <div class="card__action card__action--remove pa-3" v-if="active">
          <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
        </div>
        <div class="card__action card__action--add pa-3" v-else>
          <v-icon color="#e1e8f5"> mdi-cart-plus </v-icon>
        </div>
      </localized-link>
      <localized-link
        class="d-flex"
        :to="`/patient/order-package/${data.id}?plant=${plant}`"
        v-else
      >
        <div class="card__action card__action--remove pa-3" v-if="active">
          <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
        </div>
        <div class="card__action card__action--add pa-3" v-else>
          <v-icon color="#e1e8f5"> mdi-cart-plus </v-icon>
        </div>
      </localized-link> -->
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "-",
    },
    sub_title: {
      type: String,
      default: "-",
    },
    desc: {
      type: String,
      default: "-",
    },
    plant: {
      type: [String, Number],
      default: "",
    },
    subscribe: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkAvailableGender() {
      if (this.data.gender == null || this.data.gender == "All") {
        return true;
      } else {
        return (
          this.$store.state.profileData.person.gender.name.toLowerCase() ==
          this.data.gender.toLowerCase()
        );
      }
    },
  },
  methods: {
    addCart(param, param2) {
      if (param.has_deal) {
        this.$emit("openModalDeal", param);
      } else {
        this.$emit("addCart", param, param2);
      }
    },
    removeCart(param, param2) {
      if (param.has_deal) {
        this.$emit("openModalDeal", param);
      } else {
        this.$emit("removeCart", param, param2);
      }
    },
    addCartPackage(param) {
      this.$emit("addCartPackage", param);
    },
    removeCartPackage(param) {
      this.$emit("removeCartPackage", param);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.card {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  &__action {
    border-radius: 0 !important;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  &__action--add {
    display: flex;
    background: $main_1;
  }
  &__action--remove {
    display: flex;
    background: $red;
  }
  &__title {
    cursor: pointer;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $dark_blue_3;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $main_1;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__desc {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $gray_4;
  }
  &__deal {
    color: #213b8f;
  }
  a {
    text-decoration: none;
  }
}
</style>
